// @flow
import React from 'react';

import {Address as AddressType} from 'pb/cwn/customers';

type Props = {|
  className?: ?string,
  address: ?AddressType,
  multiline?: boolean,
  // defaulted
  component: 'span',
|};

function Address(props: Props) {
  const {address, multiline, component: C, ...rest} = props;
  if (!address) {
    return <C {...rest}>nil</C>;
  }

  return (
    <C {...rest}>
      {address.street_address} {address.route}
      {address.subpremise && ` #${address.subpremise}`}
      {multiline ? <br /> : ', '}
      {address.locality}
      {', '}
      {address.administrativeAreaLevel1} {address.postalCode}
    </C>
  );
}

Address.defaultProps = {
  component: 'span',
};

export default Address;
