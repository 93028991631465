// @flow
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  withStyles,
} from 'ui';
import {type ClassesFromFn} from 'types';
import React, {type Node, PureComponent} from 'react';

import type {GeocoderResult} from 'google/maps/GoogleMapsAPI';

const styles = theme => ({
  message: {
    marginBottom: theme.spacing(2),
  },
  option: {
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
  radio: {
    display: 'block',
    height: 0,
    width: 0,
    opacity: 0,
    '&:not(:checked) + label': {
      borderColor: 'rgba(0, 0, 0, 0.06)',
      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0)',
        boxShadow: `0 0 ${theme.spacing(2)}px rgba(0, 0, 0, 0.1)`,
        backgroundColor: 'rgba(105, 237, 126, 0.25)',
      },
    },
    '&:checked + label': {
      borderColor: 'rgba(0, 0, 0, 0)',
      boxShadow: `0 0 ${theme.spacing(2)}px rgba(0, 0, 0, 0.15)`,
      backgroundColor: 'rgba(105, 237, 126, 0.66)',
    },
  },
  label: {
    padding: theme.spacing(3),
    display: 'flex',
    cursor: 'pointer',
    transitionProperty: 'box-shadow background-color border-color',
    transitionDuration: theme.transitions.duration.short,
    borderWidth: 1,
    borderStyle: 'solid',
  },
});

type Props = {|
  open: boolean,
  classes: ClassesFromFn<typeof styles>,
  possibleAddresses: GeocoderResult[],
  rawInput: string,
  onClose(): any,
  onSubmitAddress(GeocoderResult): any,
  onSubmitRaw(): any,
  title: string,
|};

type Selection = GeocoderResult | 'rawInput';

type State = {|
  selectedAddress: ?Selection,
|};

class RefineAddressDialog extends PureComponent<Props, State> {
  state = {
    selectedAddress: null,
  };

  handleSubmit = () => {
    const {onSubmitAddress, onSubmitRaw, onClose} = this.props;
    const {selectedAddress} = this.state;
    if (!selectedAddress) {
      return;
    }

    onClose();

    if (selectedAddress === 'rawInput') {
      onSubmitRaw();
    } else {
      onSubmitAddress(selectedAddress);
    }
  };

  handleChange = (e: SyntheticInputEvent<>) => {
    const {possibleAddresses} = this.props;
    const {value} = e.target;
    const selection =
      value === 'rawInput'
        ? 'rawInput'
        : possibleAddresses.find(address => address.place_id === value);
    if (selection) {
      this.setState({selectedAddress: selection});
    }
  };

  renderOption(id: string, content: Node) {
    const {classes} = this.props;

    return (
      <div key={id} className={classes.option}>
        <input
          type="radio"
          name="refineAddressOption"
          id={id}
          value={id}
          className={classes.radio}
        />
        <label htmlFor={id} className={classes.label}>
          {content}
        </label>
      </div>
    );
  }

  render() {
    const {
      open,
      classes,
      onClose,
      possibleAddresses,
      rawInput,
      title,
    } = this.props;
    const {selectedAddress} = this.state;

    return (
      <Dialog onClose={onClose} open={open}>
        <DialogContent>
          <DialogContentText className={classes.message}>
            {title}
          </DialogContentText>
          <div role="radiogroup" onChange={this.handleChange}>
            {possibleAddresses.map(address =>
              this.renderOption(
                address.place_id,
                <Typography variant="body1">
                  {address.formatted_address.replace(', USA', '')}
                </Typography>,
              ),
            )}
            {this.renderOption(
              'rawInput',
              <div>
                <Typography variant="caption">
                  Use the address you entered:
                </Typography>
                <Typography variant="body1">{rawInput}</Typography>
              </div>,
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!Boolean(selectedAddress)}
            onClick={this.handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(RefineAddressDialog);
