//@flow
const isProd = process.env.NODE_ENV === 'production';

const basePort = parseInt(process.env.REACT_APP_APPSERVER_BASE_PORT) || 9000;

const config = isProd
  ? {
      stripeKey: 'pk_live_kV8mwgahvskAimgC1ie7lCIz',
      appointmentType: '2377902',
      gaTrackingID: 'UA-80988864-1',
      productionProxyAdminSacsid: null,
      productionProxyWebSacsid: null,
      firebase: {
        apiKey: 'AIzaSyBIGIwr1-JyRQqOjOIh-ig9NQlE1dluyWo',
        authDomain: 'commonwealth-networks.firebaseapp.com',
        projectId: 'commonwealth-networks',
      },
      protocol: 'https',
      hostname: 'common.net',
      nextPort: null,
      craPort: null,
    }
  : {
      stripeKey: 'pk_test_4Xikc2INYBIqpOTfDUMPRHzy',
      appointmentType: '2386146',
      gaTrackingID: 'UA-80988864-1',
      productionProxyAdminSacsid:
        process.env.REACT_APP_PRODUCTION_PROXY_ADMIN_SACSID,
      productionProxyWebSacsid:
        process.env.REACT_APP_PRODUCTION_PROXY_WEB_SACSID,
      firebase: {
        apiKey:
          process.env.REACT_APP_FIREBASE_API_KEY || 'fake-firebase-api-key',
        authDomain:
          process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
          'fake-firebase-auth-domain',
        projectId:
          process.env.REACT_APP_FIREBASE_PROJECT_ID ||
          'fake-firebase-project-id',
      },
      protocol: 'http',
      hostname: 'localhost',
      nextPort: basePort + 100,
      craPort: basePort + 101,
    };
export default config;

const {protocol, hostname, nextPort, craPort} = config;

export const nextOrigin = `${protocol}://${hostname}${
  nextPort ? `:${nextPort}` : ''
}`;
export const craOrigin = `${protocol}://${hostname}${
  craPort ? `:${craPort}` : ''
}`;
export const adminOrigin = isProd
  ? `${protocol}://admin.${hostname}`
  : craOrigin;
export const techOrigin = isProd
  ? `${protocol}://tech.${hostname}`
  : nextOrigin;

export const dashboardPath = isProd ? '/dashboard' : '/php';
export const signupPath = isProd ? '/signup' : '/sup';
export const adminPath = isProd ? '/v2' : '/op';

export const homepageURL = nextOrigin;
export const dashboardURL = `${craOrigin}${dashboardPath}`;
export const signupURL = `${craOrigin}${signupPath}`;
export const adminURL = `${adminOrigin}${adminPath}`;
export const techURL = `${adminOrigin}/tech`;
export const techV2URL = `${techOrigin}/tech`;
