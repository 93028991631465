// @flow
import {Address} from 'pb/cwn/customers';

export default function toSplitStrings(
  address: ?Address,
): {streetAddress: string, cityAndState: string} {
  let streetAddress = '';
  let cityAndState = '';

  if (address) {
    streetAddress = address.street_address + ' ' + address.route;
    if (address.subpremise) streetAddress += ' #' + address.subpremise;

    cityAndState = address.locality;
    if (address.administrativeAreaLevel1)
      cityAndState += ', ' + address.administrativeAreaLevel1;
  }

  return {streetAddress, cityAndState};
}
