// @flow
import React, {type Element, type ElementProps} from 'react';

import {Address as AddressType} from 'pb/cwn/customers';

type Props = ElementProps<'span'> & {
  address: ?AddressType,
  showCityState?: boolean,
};

function ShortAddress({
  address,
  showCityState,
  ...props
}: Props): Element<'span'> {
  if (!address) {
    return <span {...props}>nil</span>;
  }

  return (
    <span {...props}>
      {address.street_address} {address.route}
      {address.subpremise && ` #${address.subpremise}`}
      {showCityState &&
        `, ${address.locality}, ${address.administrativeAreaLevel1}`}
    </span>
  );
}

ShortAddress.defaultProps = {
  showCityState: false,
};

export default ShortAddress;
