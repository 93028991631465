// @flow
import {Address} from 'pb/cwn/customers';

export default function toString(address: Address): string {
  return `${address.street_address} ${address.route}${
    address.subpremise ? ` #${address.subpremise}` : ''
  }, ${address.locality}, ${address.administrativeAreaLevel1} ${
    address.postalCode
  }`;
}
