// @flow
import {Address} from 'pb/cwn/customers';
import {type GeocoderResult} from 'google/maps';

export default function geocodeToAddress(geocode: GeocoderResult): Address {
  // NOTE: Keep this function in sync with cwn/customers.GeocodeToAddress in address.go
  const address = new Address();

  for (const component of geocode.address_components) {
    for (const componentType of component.types) {
      switch (componentType) {
        case 'street_number':
          address.street_address = component.long_name;
          break;
        case 'route':
          address.route = component.long_name;
          break;
        case 'subpremise':
          address.subpremise = component.long_name;
          break;
        case 'locality':
          address.locality = component.long_name;
          break;
        case 'administrative_area_level_2':
          address.administrativeAreaLevel2 = component.short_name;
          break;
        case 'administrative_area_level_1':
          address.administrativeAreaLevel1 = component.short_name;
          break;
        case 'postal_code':
          address.postalCode = component.long_name;
          break;
        case 'country':
          address.country = component.long_name;
          break;
        default:
          break;
      }
    }
  }

  return address;
}
